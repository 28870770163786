<div class="scroll-container">
  <table
    class="time-series-table"
    #sort="matSort"
    mat-table
    matSort
    matSortActive="time"
    matSortDirection="desc"
    [dataSource]="dataSource"
  >
    <ng-container matColumnDef="time">
      <th
        mat-header-cell
        *matHeaderCellDef
        mat-sort-header
        i18n="
          Name of a column in a table that shows the time period when an issue
          occurred; e.g., 'Jan 8, 5:15PM – 6:03PM'
        "
      >
        Time
      </th>
      <td mat-cell *matCellDef="let entry">
        {{ entry.timeMs | dateWithTimezone: "h:mma, MMM d, y" | async }}
      </td>
    </ng-container>

    <ng-container matColumnDef="data">
      <th mat-header-cell *matHeaderCellDef mat-sort-header disabled>
        {{ dataColumnLabel }}
      </th>
      <td mat-cell *matCellDef="let entry">
        <ng-container
          *ngIf="valueTemplate"
          [ngTemplateOutlet]="valueTemplate"
          [ngTemplateOutletContext]="{ $implicit: entry.value }"
        ></ng-container>
        <ng-container *ngIf="!valueTemplate">
          {{ entry.value }}
        </ng-container>
      </td>
    </ng-container>

    <!-- TODO: Make table header sticky -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr
      mat-row
      *matRowDef="let row; columns: displayedColumns"
      class="clickable-mat-row"
    ></tr>
  </table>
</div>

<mat-paginator
  #paginator
  paginatorI18n
  [pageSizeOptions]="[25, 50, 100]"
  showFirstLastButtons
>
</mat-paginator>

<all-entities-view [listView]="listView" [detailView]="detailView">
  <ng-container filters>
    <ng-container [ngTemplateOutlet]="tripStagesFilterChip"></ng-container>
  </ng-container>
</all-entities-view>

<ng-template #tripStagesFilterChip>
  <mat-chip-list>
    <dropdown-multi-filter-chip
      [initialValue]="this.tripsModel.tripStages$ | async"
      [defaultValue]="this.defaultStages"
      label="Stages"
      i18n-label="
        Text shown in a table filtering trips by trip stages (such as 'in
        transit' or 'completed')
      "
      (selection)="this.tripStagesSelectionChange($event)"
      #tripStagesFilterDropdown
    >
      <dropdown-multi-option
        [value]="TripStage.NOT_STARTED"
        label="Not started"
        i18n-label="
          Shown in a dropdown. Allows the user to toggle viewing trips in the
          'not started' stage
        "
      >
      </dropdown-multi-option>
      <dropdown-multi-option
        [value]="TripStage.PENDING_DEPARTURE"
        label="Pending departure"
        i18n-label="
          Shown in a dropdown. Allows the user to toggle viewing trips in the
          'pending departure' stage
        "
      >
      </dropdown-multi-option>
      <dropdown-multi-option
        [value]="TripStage.IN_TRANSIT"
        label="In transit"
        i18n-label="
          Shown in a dropdown. Allows the user to toggle viewing trips in the
          'in transit' stage
        "
      >
      </dropdown-multi-option>
      <dropdown-multi-option
        [value]="TripStage.PENDING_ARRIVAL"
        label="Pending arrival"
        i18n-label="
          Shown in a dropdown. Allows the user to toggle viewing trips in the
          'pending arrival' stage
        "
      >
      </dropdown-multi-option>
      <dropdown-multi-option
        [value]="TripStage.COMPLETED"
        label="Completed"
        i18n-label="
          Shown in a dropdown. Allows the user to toggle viewing trips in the
          'completed' stage
        "
      >
      </dropdown-multi-option>
      <dropdown-multi-option
        [value]="TripStage.CANCELED"
        label="Canceled"
        i18n-label="
          Shown in a dropdown. Allows the user to toggle viewing trips in the
          'canceled' stage
        "
      >
      </dropdown-multi-option>
    </dropdown-multi-filter-chip>
  </mat-chip-list>
</ng-template>

<ng-template #tripDetailHeader let-trip>
  <h3 class="detail-header-name">
    {{ trip.getCustomerId() || trip.getTripId() }}
  </h3>
</ng-template>

<ng-template #tripDetailBody let-trip>
  <trip-detail
    [trip]="trip"
    (reloadTrip)="tripsModel.reloadSelectedEntity()"
  ></trip-detail>
</ng-template>

<ng-template #emptyValue> —</ng-template>

<ng-template #pageName>
  <ng-container
    i18n="
      Title of the page (also used in the navigation bar) which shows all of an
      organization's ongoing shipments
    "
  >
    Trips
  </ng-container>
</ng-template>

<ng-template #listView>
  <all-entities-list-view
    [displayedColumns]="[
      'id',
      'last_checkin',
      'last_location',
      'stage',
      'scheduled_times'
    ]"
  >
    <ng-container matColumnDef="id">
      <th
        mat-header-cell
        *matHeaderCellDef
        i18n="Name of a column in a table that shows the ID of a trip/shipment"
      >
        Trip
      </th>
      <td mat-cell *matCellDef="let trip" class="trip-id">
        {{ trip.getCustomerId() || trip.getTripId() }}
      </td>
    </ng-container>

    <ng-container matColumnDef="last_checkin">
      <th
        mat-header-cell
        *matHeaderCellDef
        i18n="
          Name of a column in a table that shows the last time a device was
          online and checked in with the server
        "
      >
        Last check-in
      </th>
      <td mat-cell *matCellDef="let trip">
        <ng-container
          *ngIf="
            tripsModel.tripIdToLastCheckInTimeMs$.get(trip.getTripId()) | async;
            else emptyValue;
            let lastCheckInTimeMs
          "
        >
          <time-ago-text [timeMs]="lastCheckInTimeMs"></time-ago-text>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="last_location">
      <th
        mat-header-cell
        *matHeaderCellDef
        i18n="
          Name of a column in a table that shows the last known location (e.g.,
          a city name) of a tracking device
        "
      >
        Last location
      </th>
      <td mat-cell *matCellDef="let trip">
        <ng-container
          *ngIf="
            tripsModel.tripIdToLastLocation$.get(trip.getTripId()) | async;
            else emptyValue;
            let latAndLng
          "
        >
          <lat-lng-link [latAndLng]="latAndLng"></lat-lng-link>
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="stage">
      <th
        mat-header-cell
        *matHeaderCellDef
        i18n="
          Name of a column in a table that shows the current phase of a
          shipment. Some possible values are: Scheduled, In Transit, Completed
        "
      >
        Stage
      </th>
      <td mat-cell *matCellDef="let trip">
        <trip-stage-name [trip]="trip"></trip-stage-name>
      </td>
    </ng-container>

    <ng-container matColumnDef="scheduled_times">
      <th
        mat-header-cell
        *matHeaderCellDef
        i18n="
          Name of a column in a table that shows the scheduled timing of a
          shipment. An example value shown in this column: 'Jun 22, 9:00AM – Jun
          23, 5:20PM'
        "
      >
        Scheduled start – end
      </th>
      <td mat-cell *matCellDef="let trip">
        <!-- TODO: Handle trips with only 'actual' times. -->
        <trip-scheduled-times
          [trip]="trip"
          class="scheduled-times"
          *ngIf="
            trip.hasScheduledStartTime() ||
              trip.hasScheduledDepartureTime() ||
              trip.hasScheduledArrivalTime() ||
              trip.hasScheduledEndTime();
            else emptyValue
          "
        ></trip-scheduled-times>
      </td>
    </ng-container>
  </all-entities-list-view>
</ng-template>

<ng-template #detailView>
  <all-entities-view-entity-detail
    [detailHeader]="tripDetailHeader"
    [detailBody]="tripDetailBody"
  ></all-entities-view-entity-detail>
</ng-template>

import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {AuthService} from '../services/auth-service';
import {BehaviorSubject} from 'rxjs';
import {filter, finalize, map, take, withLatestFrom} from 'rxjs/operators';
import {EndpointsService} from '../services/endpoints-service';
import {Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {EnvironmentType} from 'src/environments/environment-type';
import {PersistentParamsService} from '../shared/persistent-params/persistent-params.service';

@Component({
  selector: 'login-view',
  templateUrl: './login-view.component.html',
  styleUrls: ['./login-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginViewComponent implements OnInit {
  showNoOrgError$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  userEmailForNoOrgError: string;
  showDevWarningInNoOrgError: boolean;
  // We start in the LOADING state because we're not sure whether we should
  // allow the user to log in yet (we might be waiting for an auth response
  // after a log in redirect).
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  isInternetExplorer: boolean;

  constructor(
    private authService: AuthService,
    private endpointsService: EndpointsService,
    private persistentParamsService: PersistentParamsService,
    private router: Router
  ) {}

  ngOnInit() {
    this.isInternetExplorer = /Trident\/|MSIE/.test(window.navigator.userAgent);
    // Ensures we don't let the user log in repeatedly before we even know
    // their login state.
    this.authService.loggedIn$
      .pipe(
        take(1),
        finalize(() => this.loading$.next(false))
      )
      .subscribe();

    this.authService.loggedIn$
      .pipe(
        filter((loggedIn) => !!loggedIn),
        withLatestFrom(this.authService.userEmail$),
        map(([_, userEmail]) => userEmail)
      )
      .subscribe({
        next: (userEmail) => {
          // Even though we enter a loading state when the user attempts to
          // sign in, we also need it here for when we sign in users with a
          // redirect.
          this.loading$.next(true);
          this.checkIfUserIsAssociatedWithAnOrg(userEmail);
        },
        error: () => this.loading$.next(false),
      });
  }

  private checkIfUserIsAssociatedWithAnOrg(userEmail: string) {
    // We treat any standard response as the user being associated with an org;
    // when an error is thrown, we know they do not belong to an org.
    this.endpointsService.getUserByEmail(userEmail).subscribe({
      next: (resp) => {
        // Report user org to GA if we can
        if (resp.hasUser() && typeof gtag === 'function') {
          const currentUser = resp.getUser();

          gtag('set', 'user_properties', {
            org: currentUser.getOrgName(),
          });
        }

        this.redirectToHomePage();
      },
      error: () => {
        this.userEmailForNoOrgError = userEmail;
        this.showDevWarningInNoOrgError =
          environment.environmentType !== EnvironmentType.PROD;
        this.showNoOrgError$.next(true);
        this.loading$.next(false);
      },
    });
  }

  async logInWithGoogle() {
    try {
      await this.prepareToLogIn();
      await this.authService.logInWithGoogle();
    } catch (exception) {
      // TODO: Show error.
    }
  }

  async logInWithMicrosoft() {
    try {
      await this.prepareToLogIn();
      await this.authService.logInWithMicrosoft();
    } catch (exception) {
      // TODO: Show error.
    }
  }

  private async prepareToLogIn() {
    try {
      this.loading$.next(true);
      this.showNoOrgError$.next(false);
      await this.authService.logOut();
    } catch (exception) {
      // TODO: Show error.
    }
  }

  private redirectToHomePage() {
    // We don't specify an explicit path so the router redirects to whatever we
    // have set as the 'default' logged in route.
    this.router.navigateByUrl(this.persistentParamsService.updateUrl(''));
  }
}

import {
  Component,
  Input,
  ChangeDetectionStrategy,
  TemplateRef,
  EventEmitter,
  Output,
} from '@angular/core';
import {RemoteConfigService} from 'src/app/services/remote-config-service';

/** Base component for showing a list of associated entities. */
@Component({
  selector: 'base-associated-entity-list',
  templateUrl: './base-associated-entity-list.component.html',
  styleUrls: ['./base-associated-entity-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BaseAssociatedEntityListComponent<T> {
  @Input() entities: T[] | null;
  @Input() entityTemplate: TemplateRef<HTMLElement>;
  @Input() noEntitiesTemplate: TemplateRef<HTMLElement>;
  // TODO: Make this non-optional.
  @Input() editMenuOptions?: TemplateRef<HTMLElement>;

  constructor(public remoteConfigService: RemoteConfigService) {}
}

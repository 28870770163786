import {
  ChangeDetectionStrategy,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import {
  HistoricalLocation,
  HistoricalLocations,
} from '../shared/historical-location/historical-location';
import {PathLayer, ScatterplotLayer} from '@deck.gl/layers';
// @ts-ignore
import {} from 'googlemaps';
import {DeviceMapMarkerComponent} from '../shared/map/device-map-marker/device-map-marker.component';
import {Observable, Subscription} from 'rxjs';
import {GeofenceCondition, Place} from '../jspb/entity_pb';
import {NumberedClusterLayer} from '../map-container/numbered-cluster-layer';
import {Layer, RGBAColor} from '@deck.gl/core';
import {
  getFirstIncidentIndexPessimistic,
  getLastIncidentIndexPessimistic,
  timeIsDuringIncident,
} from 'src/app/shared/alert-utils';
import {LayoutService} from 'src/app/services/layout-service';
import {QueryParamService} from 'src/app/services/query-param-service';
import {LatAndLng, MapService} from '../services/map-service';
import {Position} from '@deck.gl/core/utils/positions';
import {TimeZoneService} from '../services/time-zone-service';
import {skip} from 'rxjs/operators';

// Exported for tests.
export const LAT_PARAM_NAME = 'lat';
export const LNG_PARAM_NAME = 'lng';
export const ZOOM_PARAM_NAME = 'zoom';

const GOOGLE_BLUE_700: RGBAColor = [25, 103, 210, 255];
const GOOGLE_BLUE_500: RGBAColor = [66, 133, 244, 255];
const GOOGLE_BLUE_300: RGBAColor = [138, 180, 248, 255];
const GOOGLE_BLUE_100: RGBAColor = [210, 227, 252, 255];
const GOOGLE_BLUE_50_LOW_OPACITY: RGBAColor = [232, 240, 254, 102];
const GOOGLE_RED_400: RGBAColor = [238, 103, 92, 255];
const WHITE_COLOR: RGBAColor = [255, 255, 255, 255];

// Minimum radius when zoomed really far out.
const LOCATION_CIRCLE_MIN_RADIUS = 4;
// Maximum radius when really zoomed in.
const LOCATION_CIRCLE_MAX_RADIUS = 6;
// Minimum line width when zoomed really far out.
const LOCATION_PATH_MIN_WIDTH = 5;
// Maximum line width when really zoomed in.
const LOCATION_PATH_MAX_WIDTH = 7;

// Based on the origin symbol used by Google Maps for directions.
const TRIP_ORIGIN_SYMBOL: google.maps.ReadonlySymbol = {
  path: google.maps.SymbolPath.CIRCLE,
  scale: 4,
  fillColor: '#FFFFFF',
  fillOpacity: 1.0,
  strokeColor: '#202124',
  strokeWeight: 2,
};

@Component({
  selector: 'historical-map',
  templateUrl: './historical-map.component.html',
  styleUrls: ['./historical-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HistoricalMapComponent implements OnChanges, OnDestroy {
  @Input() historicalLocations$?: Observable<HistoricalLocations>;
  @Input() tripOrigin: Place | null;
  @Input() tripDestination: Place | null;
  @Input() connectLocations: boolean = true;
  @Input() incidentTimeSec: number | null;
  @Input() resolutionTimeSec: number | null;
  @Input() geofences: GeofenceCondition[] | null;
  @ViewChild('mapContainer') mapContainer!: ElementRef;

  private subscriptions = new Subscription();
  private mostRecentLocation: HistoricalLocation | null = null;
  private mostRecentLocationMarker: google.maps.Marker | null = null;
  // Stored in ascending order (oldest to newest) so we can append new data to
  // the end. Deck.gl will get messed up if we add items to the front.
  private locationHistory: HistoricalLocation[] = [];
  private incidentStartIndexPessimistic: number | null = null;
  private incidentEndIndexPessimistic: number | null = null;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private injector: Injector,
    private layoutService: LayoutService,
    private mapService: MapService,
    private queryParamService: QueryParamService,
    private timeZoneService: TimeZoneService
  ) {
    // Edge case: if the user updates their time zone while the info window
    // is open, close the window so we don't show the incorrect time.
    // It would be preferable to simply update the time instead, but map
    // info windows are outside of Angular's rendering system: we render
    // a component as a string and pass it to Google Maps; thus, doing that
    // in an "elegant" way is non-trivial and probably not worth the effort.
    this.subscriptions.add(
      this.timeZoneService.selectedTimeZone$
        .pipe(
          // Don't close the tooltip the first time we get a timezone.
          skip(1)
        )
        .subscribe({next: () => this.mapService.closeTooltip()})
    );
  }

  ngAfterViewInit() {
    this.createMap();
    this.initializeMap();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngOnChanges() {
    if (!this.mapService.getInitialized()) {
      return;
    }
    this.resetMapData();
    this.subscriptions.unsubscribe();
    this.subscriptions = new Subscription();
    this.mapService.reset();
    this.createMap();
    this.initializeMap();
  }

  private showTripMarkers() {
    if (this.tripOrigin) {
      this.createMarkerForPlace(this.tripOrigin, TRIP_ORIGIN_SYMBOL);
    }
    if (this.tripDestination) {
      this.createMarkerForPlace(this.tripDestination);
    }
  }

  private resetMapData() {
    this.locationHistory = [];
    this.mostRecentLocation = null;
  }

  private createMarkerForPlace(
    place: Place,
    symbol?: google.maps.ReadonlySymbol
  ) {
    if (!place.hasPointAndRadius()) {
      return;
    }

    const latLng = place.getPointAndRadius().getPoint();
    // TODO: Add click listener to show tooltip.
    this.mapService.addMarker(
      {
        lat: latLng.getLatitudeMicro() / 1e6,
        lng: latLng.getLongitudeMicro() / 1e6,
      },
      symbol
    );
  }

  private updateMapData(historicalLocations: HistoricalLocations) {
    this.updateIncidentData(historicalLocations.locations);

    // Add new data to the end; deck.gl gets confused if we prepend data to the
    // array, but it's OK with data added to the end.
    this.locationHistory.push(...historicalLocations.locations);
    // this.incidentLocations.push(...incidentLocations);
    // Note: we do the following even if there is no new data since we need to
    // redraw the (empty) layers if data is reset. This isn't too bad
    // performance-wise since creating a new deck.gl layer is cheap due to
    // their diffing strategy.
    this.mapService.updateVisualizationLayers(this.createVisualizationLayers());
  }

  private updateIncidentData(newLocations: HistoricalLocation[]) {
    // There is no incident to deal with; return early.
    if (!this.incidentTimeSec) {
      return;
    }

    const newLocationsTimesSec = newLocations.map(
      (location) => location.timeMs / 1e3
    );

    // If we haven't yet calculated the start of the incident, do so. We do
    // not re-calculate it upon new data since we don't properly support
    // backfilled data in autorefresh...yet.
    if (!this.incidentStartIndexPessimistic) {
      this.incidentStartIndexPessimistic = getFirstIncidentIndexPessimistic(
        newLocationsTimesSec,
        this.incidentTimeSec,
        this.resolutionTimeSec,
        this.locationHistory.length
      );
    }

    this.incidentEndIndexPessimistic = getLastIncidentIndexPessimistic(
      newLocationsTimesSec,
      this.incidentTimeSec,
      this.resolutionTimeSec,
      this.locationHistory.length
    );
  }

  private createMap() {
    this.mapService.createMap(this.mapContainer.nativeElement);
    this.mapService.updateVisualizationLayers(this.createVisualizationLayers());
  }

  private initializeMap() {
    this.showTripMarkers();
    this.maybeSetInitialBoundsFromTripData();
    if (!this.historicalLocations$) {
      return;
    }
    this.subscriptions.add(
      this.historicalLocations$.subscribe({
        next: (historicalLocations: HistoricalLocations) => {
          if (!historicalLocations.isUpdate) {
            this.resetMapData();
          }
          this.maybeSetInitialBoundsFromLocationData(historicalLocations);
          this.updateMapData(historicalLocations);
          // The "most recent location marker" is only shown when it is a
          // sequence of locations.
          if (this.connectLocations) {
            this.updateMostRecentLocation();
          }
        },
      })
    );
  }

  private maybeSetInitialBoundsFromLocationData(
    historicalLocations: HistoricalLocations
  ) {
    this.mapService.setInitialMapBounds(
      historicalLocations.locations.map((historicalLocation) => ({
        lat: historicalLocation.lat,
        lng: historicalLocation.lng,
      }))
    );
  }

  private maybeSetInitialBoundsFromTripData() {
    if (!this.tripOrigin && !this.tripDestination) {
      return;
    }
    this.mapService.setInitialMapBounds(
      [
        placeToLatAndLng(this.tripOrigin),
        placeToLatAndLng(this.tripDestination),
      ].filter((latLng) => latLng != null)
    );
  }

  private updateMostRecentLocation() {
    this.mostRecentLocation = this.getMostRecentLocation();

    if (!this.mostRecentLocation) {
      if (this.mostRecentLocationMarker) {
        this.mostRecentLocationMarker.setMap(null);
        this.mostRecentLocationMarker = null;
      }
      return;
    }

    const googleMapsLatLng = new google.maps.LatLng(
      this.mostRecentLocation.lat,
      this.mostRecentLocation.lng
    );
    if (this.mostRecentLocationMarker) {
      this.mostRecentLocationMarker.setPosition(googleMapsLatLng);
    } else {
      this.mostRecentLocationMarker = this.mapService.addMarker(
        this.mostRecentLocation
      );
      this.mostRecentLocationMarker.addListener('click', () => {
        this.updateMapTooltip(this.mostRecentLocation, true);
      });
    }
  }

  private getMostRecentLocation(): HistoricalLocation | null {
    return this.locationHistory.length === 0
      ? null
      : this.locationHistory[this.locationHistory.length - 1];
  }

  private getMapInfoWindowContent(recentLocation: HistoricalLocation): string {
    // TODO: Determine what these info windows will look like for
    // both devices and trips. If they're largely equivalent, rename the
    // DeviceMapMarkerComponent. If they're different but have some overlap,
    // move the component out of the directory and make an interface or
    // something.
    const mapMarkerFactory =
      this.componentFactoryResolver.resolveComponentFactory(
        DeviceMapMarkerComponent
      );
    const mapMarkerComponent = mapMarkerFactory.create(this.injector);
    mapMarkerComponent.instance.recentLocation = recentLocation;
    mapMarkerComponent.changeDetectorRef.detectChanges();
    return mapMarkerComponent.location.nativeElement.innerHTML;
  }

  private updateMapTooltip(
    location: HistoricalLocation,
    mostRecentLocation: boolean = false
  ) {
    const anchor = mostRecentLocation
      ? this.mostRecentLocationMarker
      : location;
    this.mapService.updateTooltip(
      this.getMapInfoWindowContent(location),
      anchor
    );
  }

  private createVisualizationLayers(): Layer<any>[] {
    if (!this.connectLocations) {
      return [this.createNumberedClusterLayer()];
    }
    if (this.incidentTimeSec) {
      return this.createConnectedPathsWithIncident();
    }
    return this.createConnectedPaths();
  }

  private createConnectedPaths() {
    return [
      this.createPathLayer(
        'historical-map-path',
        this.locationHistory,
        (_) => GOOGLE_BLUE_500
      ),
      // Actual data points. Intentionally drawn after the path so the points
      // appear on top.
      this.createScatterplotLayerForDataPoints(
        'historical-map-points',
        this.locationHistory,
        (_: HistoricalLocation) => GOOGLE_BLUE_500
      ),
    ];
  }

  private createConnectedPathsWithIncident() {
    const preIncidentPathPoints = this.locationHistory.slice(
      0,
      this.incidentStartIndexPessimistic + 1 // + 1 because the index is exclusive
    );
    const incidentPathPoints = this.locationHistory.slice(
      this.incidentStartIndexPessimistic,
      this.incidentEndIndexPessimistic + 1 // + 1 because the index is exclusive
    );
    const postIncidentPathPoints = this.locationHistory.slice(
      this.incidentEndIndexPessimistic
    );
    return [
      // Show the geofence(s) first so they are below everything else.
      this.createGeofenceLayer(this.geofences),
      // The order of the path layers is arbitrary since they do not overlap.
      this.createPathLayer(
        'historical-map-pre-incident-path',
        preIncidentPathPoints,
        (_) => GOOGLE_BLUE_500
      ),
      this.createPathLayer(
        'historical-map-incident-path',
        incidentPathPoints,
        (_) => GOOGLE_RED_400
      ),
      this.createPathLayer(
        'historical-map-post-incident-path',
        postIncidentPathPoints,
        (_) => GOOGLE_BLUE_500
      ),
      // Actual data points. Intentionally drawn after all the paths so the
      // points appear on top.
      this.createScatterplotLayerForDataPoints(
        'historical-map-points',
        this.locationHistory,
        (historicalLocation: HistoricalLocation) =>
          timeIsDuringIncident(
            historicalLocation.timeMs / 1e3,
            this.incidentTimeSec,
            this.resolutionTimeSec
          )
            ? GOOGLE_RED_400
            : GOOGLE_BLUE_500
      ),
    ];
  }

  private createGeofenceLayer(geofenceConditions: GeofenceCondition[] | null) {
    if (geofenceConditions == null || geofenceConditions.length === 0) {
      return;
    }
    return new ScatterplotLayer({
      id: `historical-map-geofences`,
      data: geofenceConditions,
      getPosition: (geofenceCondition: GeofenceCondition) => {
        const centerPoint = geofenceCondition
          .getPlace()
          .getPointAndRadius()
          .getPoint();
        // Note: deck.gl expects (lng,lat,alt) because they're masochists.
        return [
          centerPoint.getLongitudeMicro() / 1e6,
          centerPoint.getLatitudeMicro() / 1e6,
          0,
        ];
      },
      getLineColor: () => GOOGLE_BLUE_300,
      getRadius: (geofenceCondition: GeofenceCondition) =>
        geofenceCondition.getPlace().getPointAndRadius().getRadiusMeters() +
        geofenceCondition.getRadiusMeters(),
      pickable: false,
      stroked: true,
      filled: true,
      getFillColor: GOOGLE_BLUE_50_LOW_OPACITY,
      lineWidthMinPixels: 4,
      lineWidthMaxPixels: 5,
    });
  }

  private createNumberedClusterLayer() {
    return new NumberedClusterLayer({
      id: `entity-locations-${new Date().getTime()}`,
      data: this.locationHistory,
      getPosition: (recentLocation: HistoricalLocation): Position =>
        // Note: deck.gl expects (lng,lat,alt) because they're masochists.
        [recentLocation.lng, recentLocation.lat, 0],
      onClick: (info) => {
        // If the user clicked on a cluster, we don't want to show an info
        // window.
        if (info.object.hasOwnProperty('cluster')) {
          const locations: HistoricalLocation[] = (info.object as any).points;
          this.mapService.updateBoundsToFit(
            locations.map((location) => ({
              lat: location.lat,
              lng: location.lng,
            }))
          );
        } else {
          this.updateMapTooltip(info.object as HistoricalLocation);
        }
      },
    });
  }

  private createPathLayer(
    id: string,
    data: HistoricalLocation[],
    colorFn: (HistoricalLocation) => RGBAColor
  ) {
    return new PathLayer({
      id,
      // Intentionally inside an array, since the data parameter represents
      // a list of paths, and every path is an array of points.
      data: [data],
      getColor: colorFn,
      getPath: (locations: HistoricalLocation[]) =>
        locations.map((location) => [location.lng, location.lat]),
      jointRounded: true,
      capRounded: true,
      // Draw the shortest path possible when two vertices cross the 180th meridian
      // rather than always taking the "long way." See
      // https://drive.google.com/file/d/1GRulqUwqxYRK41G-k95pgCfq98O3cmzZ/view?usp=sharing
      wrapLongitude: true,
      widthMinPixels: LOCATION_PATH_MIN_WIDTH,
      widthMaxPixels: LOCATION_PATH_MAX_WIDTH,
    });
  }

  private createScatterplotLayerForDataPoints(
    id: string,
    data: HistoricalLocation[],
    colorFn: (HistoricalLocation) => RGBAColor
  ) {
    return new ScatterplotLayer({
      id,
      data,
      onClick: (info) =>
        this.updateMapTooltip(info.object as HistoricalLocation),
      getPosition: (recentLocation: HistoricalLocation) =>
        // Note: deck.gl expects (lng,lat,alt) because they're masochists.
        [recentLocation.lng, recentLocation.lat, 0],
      getLineColor: colorFn,
      pickable: true,
      stroked: true,
      filled: true,
      getFillColor: WHITE_COLOR,
      radiusMinPixels: LOCATION_CIRCLE_MIN_RADIUS,
      radiusMaxPixels: LOCATION_CIRCLE_MAX_RADIUS,
      lineWidthMinPixels: 3,
      lineWidthMaxPixels: 4,
    });
  }
}

function placeToLatAndLng(place: Place | null): LatAndLng | null {
  if (!place || !place.hasPointAndRadius()) {
    return null;
  }
  const latLng = place.getPointAndRadius().getPoint();
  return {
    lat: latLng.getLatitudeMicro() / 1e6,
    lng: latLng.getLongitudeMicro() / 1e6,
  };
}

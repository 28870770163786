<div class="container">
  <div class="section">
    <mat-icon class="section-icon">timeline</mat-icon>
    <div class="section-body">
      <trip-stage-name [trip]="trip"></trip-stage-name>
      <div
        class="current-stage-start-time"
        *ngIf="
          trip.getCurrentStage() !== TripStage.NOT_STARTED &&
            trip.getCurrentStageStartTime();
          let stageStartTime
        "
      >
        <ng-container
          i18n="
            Label next to the time when the status of something just changed. In
            context, this is used in a phrase like 'since Jan 23, 12:34PM'. For
            example, this might be displayed under a delivery's status
            designation like 'in transit' or 'on the way' to indicate that the
            incoming delivery was shipped at 12:34PM on January 23rd.
          "
        >
          since
        </ng-container>

        {{
          stageStartTime.getSeconds() * 1e3
            | dateWithTimezone: "MMM d, h:mma"
            | async
        }}
      </div>
    </div>
  </div>

  <!-- TODO: Handle trips with only 'actual' times. -->
  <div
    class="timing-section section"
    *ngIf="
      trip.hasScheduledStartTime() ||
      trip.hasScheduledDepartureTime() ||
      trip.hasScheduledArrivalTime() ||
      trip.hasScheduledEndTime()
    "
  >
    <mat-icon class="section-icon">schedule</mat-icon>
    <div class="section-body">
      <trip-scheduled-times [trip]="trip"></trip-scheduled-times>
    </div>
  </div>

  <div class="section eta-container" *ngIf="etaMs$ | async; let etaMs">
    <mat-icon class="section-icon">drive_eta</mat-icon>
    <div class="section-body">
      <span
        i18n="
          Label for the ETA (estimated time of arrival) of a shipment. The value
          shown will be something like 'Feb 15, 7:01pm UTC'
        "
        >ETA:</span
      >
      <span class="eta-value"
        >{{ etaMs | dateWithTimezone: "MMM d, h:mma" | async }}
      </span>
    </div>
  </div>

  <map-container
    *ngIf="trip.hasOrigin() || trip.hasDestination()"
    class="map"
    [haveMapData]="true"
  >
    <historical-map
      [tripOrigin]="trip.getOrigin()"
      [tripDestination]="trip.getDestination()"
    >
    </historical-map>
  </map-container>

  <mat-expansion-panel class="associations-expansion-panel" [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title
        class="expansion-panel-header"
        i18n="
          Title of the page (also used in the navigation bar) which shows all of
          the devices an organization owns
        "
      >
        Devices
      </mat-panel-title>
    </mat-expansion-panel-header>

    <ng-container
      *ngIf="trip.getDevicesList().length > 0; else noAssociatedDevices"
    >
      <div *ngFor="let device of trip.getDevicesList()" class="device-entry">
        <a
          class="device-entry-id"
          [routerLink]="['/devices', device.getDeviceId()]"
          >{{ device.getDeviceId() }}</a
        >
      </div>
    </ng-container>
  </mat-expansion-panel>

  <mat-expansion-panel class="associations-expansion-panel" [expanded]="true">
    <mat-expansion-panel-header>
      <mat-panel-title
        class="expansion-panel-header"
        i18n="
          Name of a column in a table that shows the IDs of the assets
          associated with a shipment. An 'asset' is part of a shipment. For
          example, a 'shipment' might correspond to a large shipping container
          containing 10 boxes ('assets'), each of which has a unique ID
        "
      >
        Assets
      </mat-panel-title>
    </mat-expansion-panel-header>

    <trip-asset-list
      [assets]="trip.getAssetsList()"
      [tripIdentifier]="tripIdentifier"
      (reloadAssets)="this.reloadTrip.emit()"
    ></trip-asset-list>
  </mat-expansion-panel>
</div>

<ng-template #noAssociatedDevices>
  <p
    class="no-associated-devices"
    i18n="
      Empty state message shown when there are no tracker devices that have been
      paired with the current shipment
    "
  >
    No associated devices
  </p>
</ng-template>

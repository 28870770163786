import {Injectable} from '@angular/core';
import {AllEntitiesModel} from '../all-entities-view/all-entities-model';
import {combineLatest, Observable} from 'rxjs';
import {
  GetRequest,
  ListRequest,
  ListResponse,
} from '../all-entities-view/all-entities-view.component';
import {map, switchMap} from 'rxjs/operators';
import {Device} from '../jspb/entity_pb';
import {
  CurrentDeviceState,
  ListDeviceStatesResponse,
} from '../jspb/metrics_api_pb';
import {CountDevicesResponse} from '../jspb/org_api_pb';
import {EndpointsService} from '../services/endpoints-service';
import {ProgressBarService} from '../services/progress-bar-service';
import {QueryParamService} from '../services/query-param-service';
import {EntityType} from '../shared/entity';
import {SearchResultSection, ToolbarService} from '../services/toolbar-service';
import {Router} from '@angular/router';

export const ON_TRIP_PARAM_NAME = 'on_trip';

@Injectable()
export class DevicesModel extends AllEntitiesModel {
  onTrip$: Observable<boolean>;

  constructor(
    endpointsService: EndpointsService,
    progressBarService: ProgressBarService,
    queryParamService: QueryParamService,
    router: Router,
    toolbarService: ToolbarService
  ) {
    super(
      endpointsService,
      progressBarService,
      queryParamService,
      router,
      toolbarService
    );
    this.onTrip$ = this.queryParamService.getBooleanParam(ON_TRIP_PARAM_NAME);
    this.subscriptions.add(
      this.onTrip$.subscribe({
        next: () => this.forceRefresh(),
      })
    );
  }

  getEntity(_: GetRequest): Observable<Device> {
    throw new Error('Detail view not implemented for All Devices page');
  }

  listEntities(
    listRequest: ListRequest
  ): Observable<ListResponse<CurrentDeviceState>> {
    const filters$ = combineLatest(this.onTrip$);
    return filters$.pipe(
      switchMap(([onTrip]) =>
        this.endpointsService
          .listDeviceStates({
            pageSize: listRequest.pageSize,
            pageToken: listRequest.pageToken,
            searchString: listRequest.searchString,
            onTrip: !!onTrip,
          })
          .pipe(
            map((listDeviceStatesResponse: ListDeviceStatesResponse) => ({
              entities: listDeviceStatesResponse.getDeviceStatesList(),
              nextPageToken: listDeviceStatesResponse.getNextPageToken(),
            }))
          )
      )
    );
  }

  countEntities(): Observable<number> {
    const filters$ = combineLatest(this.onTrip$);
    return filters$.pipe(
      switchMap(([onTrip]) =>
        this.endpointsService
          .countDevices({
            searchString: this.searchValueInternal$.value,
            onTrip: !!onTrip,
          })
          .pipe(
            map((countDevicesResponse: CountDevicesResponse) =>
              countDevicesResponse.getDeviceCount()
            )
          )
      )
    );
  }

  getScoutIdForEntity(
    currentDeviceState: CurrentDeviceState | null
  ): string | null {
    return currentDeviceState && currentDeviceState.getDeviceId();
  }

  watchesEntityIdQueryParam(): boolean {
    return false;
  }

  getSearchResultsFromEntities(
    currentDeviceStates: CurrentDeviceState[]
  ): SearchResultSection[] {
    return currentDeviceStates.reduce(
      (searchResultSections, currentDeviceState) => {
        searchResultSections[0].results.push({
          entity: currentDeviceState,
          label: currentDeviceState.getDeviceId(),
          uniqueId: currentDeviceState.getDeviceId(),
        });
        // TODO: Uncomment when the Assets page is launched
        // if (currentDeviceState.getAssetsList().length > 0) {
        //   searchResultSections[1].results.push(
        //     ...currentDeviceState.getAssetsList().map((asset: Asset) => ({
        //       entity: asset,
        //       label: asset.getCustomerId() || asset.getAssetId(),
        //       uniqueId: asset.getAssetId(),
        //     }))
        //   );
        // }
        return searchResultSections;
      },
      [
        {type: EntityType.DEVICE, results: []},
        // {type: EntityType.ASSET, results: []},
      ]
    );
  }
}

import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../services/auth-service';
import {take} from 'rxjs/operators';
import {MatSnackBar} from '@angular/material/snack-bar';
import {QueryParamService} from '../services/query-param-service';
import {PersistentParamsService} from '../shared/persistent-params/persistent-params.service';

@Component({
  selector: 'beta-mode',
  template: ``,
  styles: [],
})
export class BetaModeComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private persistentParamsService: PersistentParamsService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.authService.internalUser$.pipe(take(1)).subscribe({
      next: (internalUser) => {
        if (!internalUser) {
          this.router.navigateByUrl(
            this.persistentParamsService.updateUrl('/')
          );
          return;
        }

        // `url` is an array of the path parts split by '/'. For example,
        // 'admin/enableBetaMode' corresponds to ['admin', 'enableBetaMode'].
        const path = this.route.snapshot.url[1].path;
        if (path === 'enableBetaMode') {
          this.setBetaModeAndShowSnackBar(true);
        } else if (path === 'disableBetaMode') {
          this.setBetaModeAndShowSnackBar(false);
        }
        this.router.navigateByUrl(this.persistentParamsService.updateUrl('/'));
      },
    });
  }

  private setBetaModeAndShowSnackBar(betaMode: boolean) {
    // TODO: Re-enable this once we have a workaround for the IE11
    // Proxy issue (https://github.com/angular/angularfire/issues/2296)
    // this.firebaseAnalytics.setUserProperties({
    //   internal: String(betaMode),
    // });
    // This text does not need to be translated since it's only
    // shown to internal users.
    const message = `Beta mode ${betaMode ? 'enabled' : 'disabled'}`;
    this.snackBar.open(message);
  }
}
